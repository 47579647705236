import React from "react"
import FormDialog from "./FormDialog"

interface Props {
  children: any
  className?: string
}

const FormController = ({ className, children }: Props) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const openForm = () => setModalOpen(true)

  return (
    <>
      <span className={className} onClick={openForm}>
        {children}
      </span>
      <FormDialog
        title="Contattaci"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        formParameters={{
          portalId: "8625213",
          formId: "2b6cefef-4f3b-4d2e-ab03-55f9ff4a3b92",
        }}
      />
    </>
  )
}

export default FormController
