import React from "react"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { makeStyles, Typography } from "@material-ui/core"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { FadeIn } from "../../../ui/animations"
import { whenMobile } from "../../../root/ui/mui"
import { ContactsPageQuery } from "../../../__generated__/ContactsPageQuery"
import FormController from "../../../ui/molecules/ContactsForm/FormController"

interface Props {
  data: ContactsPageQuery
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: ({ src }: any) => `url(${src})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    position: "relative",
  },
  contentCta: {
    fontSize: "15px",
    width: "100%",
    color: "#FFFFFF",
    height: "22px",
    textAlign: "center",
    letterSpacing: "4.21px",
    marginBottom: "60px",
    cursor: "pointer",

    [whenMobile(theme)]: {
      marginBottom: "32px",
    },
  },
  logoContainer: {
    width: "100%",
    textAlign: "center",
    height: "19px",
  },
  logo: {
    marginRight: "20px",
    marginLeft: "20px",
    height: "19px",
    width: "19px",
  },
  arrow: {
    color: "#FFFFFF",
    position: "absolute",
    marginTop: "-5px",
  },
  frasetta: {
    color: "white",
    textAlign: "center",
  },
  content: {
    top: "36vh",
    position: "absolute",
    width: "100%",

    [whenMobile(theme)]: {
      top: "24vh",
    },

    "@media (max-height:550px)": {
      top: "18vh",
    },
  },
}))

const ContactsContainer = ({ data }: Props) => {
  const resource = data.prismicContactsPage?.data
  const src = resource?.content_background_image?.url
  const classes = useStyles({ src })
  return (
    <div className={classes.background}>
      <DefaultLayout
        footerStyle="transparent"
        headerStyle="transparent"
        footerLogo="none"
      >
        <div className={classes.content}>
          <FadeIn>
            <Typography variant="h4" className={classes.frasetta}>
              {data.prismicContactsPage?.data?.content_title?.text}
            </Typography>
            <FormController>
              <p className={classes.contentCta}>
                {resource?.content_cta_label?.text?.toUpperCase()}
                <span className={classes.arrow}>
                  <ArrowRightAltIcon />
                </span>
              </p>
            </FormController>
            {/* <a
              href={`mailto:${resource?.cta_mail_target}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className={classes.contentCta}>
                {resource?.content_cta_label?.text?.toUpperCase()}
                <span className={classes.arrow}>
                  <ArrowRightAltIcon />
                </span>
              </p>
            </a> */}
            {/* <div className={classes.logoContainer}>
              {footer.social.links.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={link.icon.fluid.src} className={classes.logo} />
                </a>
              ))}
            </div> */}
          </FadeIn>
        </div>
      </DefaultLayout>
    </div>
  )
}

export default ContactsContainer
