import { makeStyles } from "@material-ui/core"
import React from "react"
import { HubspotForm } from "../../../root/crm/hubspot"
import HubspotScript from "../../../root/crm/hubspot/HubspotScript"
import { whenMobile } from "../../../root/ui/mui"
import { CustomDialog, CustomDialogHead } from "../Dialogs"

interface Props {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  formParameters: any
  preload?: boolean
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "45vw",

    [whenMobile(theme)]: {
      minWidth: "90vw",
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const FormDialog = ({
  open,
  onClose,
  title,
  formParameters,
  preload,
}: Props) => {
  const classes = useStyles()
  return (
    <>
      {preload && <HubspotScript />}
      <CustomDialog
        open={open}
        onClose={onClose}
        head={
          <CustomDialogHead closePosition="right" onClose={onClose}>
            {title}
          </CustomDialogHead>
        }
        headPb={0}
        userClass={{
          container: classes.dialog,
        }}
      >
        <div className={classes.dialogContent}>
          <HubspotForm formParams={formParameters} />
        </div>
      </CustomDialog>
    </>
  )
}

export default FormDialog
