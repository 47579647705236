import React, { useEffect, useState } from "react"
import { randomId } from "../../../common/utils/stringUtils"
import HubspotScript from "../HubspotScript"

interface Props {
  title?: React.ReactElement
  formParams: any
}

const HubspotForm = ({ title, formParams }: Props) => {
  const hubspot = () => (window as any).hbspt

  const [formInitialized, setFormInitialized] = useState(false)
  const [id] = useState(randomId())

  const initializeForm = () => {
    if (formInitialized) {
      return
    }
    hubspot().forms.create({
      ...formParams,
      target: `#${id}`,
    })
    setFormInitialized(true)
  }

  useEffect(() => {
    if (hubspot()) {
      initializeForm()
    }
  }, [])

  return (
    <div style={{ width: "100%" }}>
      {title}
      <div id={id}></div>
      {hubspot() === undefined && <HubspotScript onLoad={initializeForm} />}
    </div>
  )
}

export default HubspotForm
