import React, { AriaAttributes } from "react"
import { Close } from "@material-ui/icons"
import { Fab, makeStyles, useTheme } from "@material-ui/core"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import { getMuiColor } from "../../../root/ui/mui"
import { ThemeColor } from "../../../root/common"

type FabSize = "small" | "medium" | "large"

interface Props extends AriaAttributes {
  color?: ThemeColor
  colorValue?: string
  fontColor?: ThemeColor
  children: React.ReactNode
  onClick?: () => void
  className?: string
  size?: FabSize
}

const useStyles = makeStyles(() => ({
  root: {
    color: ({ fontColor }: any) => fontColor,
    minHeight: "inherit",
    backgroundColor: ({ color }: any) => color,

    "&:hover": {
      backgroundColor: ({ color }: any) => color,
    },
  },
}))

export default function CustomFab({
  color,
  colorValue,
  fontColor,
  children,
  onClick,
  size,
  className,
  ...rest
}: Props) {
  const theme = useTheme()
  const classes = useStyles({
    color: color ? getMuiColor(theme, color) : colorValue,
    fontColor: fontColor,
  })
  return (
    <Fab
      className={className}
      classes={{ root: classes.root }}
      onClick={onClick}
      size={size}
      {...rest}
    >
      {children as any}
    </Fab>
  )
}

interface IconFabProps {
  color?: ThemeColor
  colorValue?: string
  fontColor?: ThemeColor
  onClick?: () => void
  size?: FabSize
  className?: string
}

export const CloseFab = ({ ...props }: IconFabProps) => {
  return (
    <CustomFab {...props}>
      <Close />
    </CustomFab>
  )
}

export const BackFab = ({ ...props }: IconFabProps) => {
  return (
    <CustomFab {...props}>
      <KeyboardArrowLeftIcon />
    </CustomFab>
  )
}
