import useMediaQuery from "@material-ui/core/useMediaQuery"
import React from "react"
import Dialog from "@material-ui/core/Dialog"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { Slide } from "@material-ui/core"
import classnames from "classnames"
import { ThemeBreakpoint, ThemeColor } from "../../../root/common"
import { getMuiColor, whenMobile } from "../../../root/ui/mui"

export type DialogOpenDirection = "left" | "right" | "up" | "down"
export type DialogPosition = "left" | "right" | "top" | "bottom" | "center"

interface Props {
  open: boolean
  onClose?: () => void
  fullScreen?: boolean
  fullScreenMaxBreakpoint: ThemeBreakpoint
  fullWidth?: boolean
  head?: React.ReactNode
  headPb?: number
  children?: React.ReactNode
  actions?: React.ReactNode
  backgoundColor?: ThemeColor
  padding?: string | number
  noGutters?: boolean
  noMargins?: boolean
  mt?: number
  mb?: number
  openDirection: DialogOpenDirection
  closeDirection?: DialogOpenDirection
  openSpeed?: number
  closeSpeed?: number
  position?: DialogPosition
  userClass: {
    head?: string
    container?: string
    backDrop?: string
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ backgoundColor }: any) => backgoundColor,
    color: ({ color }: any) => color,
    padding: ({ padding, noGutters }: any) =>
      padding ?? (noGutters ? 0 : theme.spacing(2, 1)),
    display: "flex",
    flexDirection: "column",
    width: ({ fullWidth }: any) => (fullWidth ? "100%" : undefined),
    margin: ({ noMargins }: any) => noMargins && "0",
    borderBottomLeftRadius: ({ position }: any) =>
      position === "bottom" ? 0 : undefined,
    borderBottomRightRadius: ({ position }: any) =>
      position === "bottom" ? 0 : undefined,
    marginTop: ({ mt }: any) => (mt ? theme.spacing(mt) : undefined),
    marginBottom: ({ mb }: any) => (mb ? theme.spacing(mb) : undefined),
  },
  head: {
    paddingBottom: ({ headPb }: any) => theme.spacing(headPb ?? 2),
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    [whenMobile(theme)]: {
      overflow: "scroll",
    },
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
  container: {
    alignItems: ({ position }: any) => {
      switch (position) {
        case "bottom":
          return "flex-end"
        case "top":
          return "flex-start"
        case "center":
        default:
          return "center;"
      }
    },
    justifyContent: ({ position }: any) => {
      switch (position) {
        case "left":
          return "flex-start"
        case "right":
          return "flex-end"
        case "center":
        default:
          return "center;"
      }
    },
  },
}))

interface TransitionTimeout {
  enter?: number
  exit?: number
}

interface CustomTransitionProps extends TransitionProps {
  direction: DialogOpenDirection | undefined
  timeout?: TransitionTimeout
}

const Transition = React.forwardRef(function Transition(
  props: CustomTransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />
})

export const CustomDialog = ({
  open,
  mt,
  mb,
  onClose,
  fullScreen,
  fullScreenMaxBreakpoint,
  fullWidth,
  head,
  headPb,
  children,
  actions,
  backgoundColor,
  padding,
  noGutters,
  openDirection,
  closeDirection,
  openSpeed,
  closeSpeed,
  position,
  noMargins,
  userClass,
}: Props) => {
  const theme = useTheme()
  const dialogFullScreen =
    fullScreen && useMediaQuery(theme.breakpoints.down(fullScreenMaxBreakpoint))
  const classes = useStyles({
    backgoundColor: backgoundColor
      ? getMuiColor(theme, backgoundColor)
      : undefined,
    // color: backgoundColor ? getMuiColorContrast(theme, backgoundColor) : undefined,
    padding,
    noGutters,
    fullWidth,
    position,
    noMargins,
    headPb,
    mt,
    mb,
  })
  return (
    <Dialog
      classes={{
        paper: classnames(classes.root, userClass.container),
        container: classes.container,
      }}
      open={open}
      TransitionComponent={Transition}
      TransitionProps={
        {
          direction: open ? openDirection : closeDirection ?? openDirection,
          timeout: {
            enter: openSpeed,
            exit: closeSpeed,
          },
        } as CustomTransitionProps
      }
      onClose={onClose}
      fullScreen={dialogFullScreen}
      BackdropProps={{
        classes: { root: userClass.backDrop },
      }}
    >
      {head ? (
        <div className={classnames(classes.head, userClass.head)}>{head}</div>
      ) : undefined}
      {children ? <div className={classes.content}>{children}</div> : undefined}
      {actions ? <div className={classes.actions}>{actions}</div> : undefined}
    </Dialog>
  )
}

CustomDialog.defaultProps = {
  fullScreenMaxBreakpoint: "xs",
  openDirection: "down",
  openSpeed: 450,
  closeSpeed: 150,
  DialogPosition: "center",
  noMargins: false,
  userClass: {},
}
