import React from "react"
import Script from "react-load-script"

interface Props {
  onLoad?: () => void
}

const HubspotScript = ({ onLoad }: Props) => {
  return <Script url="https://js.hsforms.net/forms/shell.js" onLoad={onLoad} />
}

export default HubspotScript
